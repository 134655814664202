@import "../../custom.scss";

.servicesContainer {
	font-family: $text_bodoni;
	font-optical-sizing: auto;
	text-align: center;
	vertical-align: middle;
	margin: 5rem auto;
}

.servicesTop {
	margin: 5rem auto;

	.servicesSubtitle {
		font-size: 1.3rem;
		width: 50%;
		margin: auto;

		display: flex;
		flex-direction: column;

		margin: 3rem auto;

		font-family: $text_bodoni;

		.quoteLeft {
			font-size: 5rem;
		}

		.quoteText {
			width: 100%;
			margin-left: -1rem;
			text-align: center;
			z-index: 2;
		}

		.quoteRight {
			font-size: 5rem;
		}
	}
}

.serviceContentContainer {
	display: flex;
	flex-direction: column;
	gap: 8rem;

	margin: 8rem 5rem;

	.serviceCardsContainer {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

mark.scrollHighlight {
	// Reference: https://frontendmasters.com/blog/highlight-text-when-a-user-scrolls-down-to-that-piece-of-text/
	background-size: 0 100%;
	background-repeat: no-repeat;
	background-color: transparent;
	background-image: linear-gradient(
		rgb($darkestPink, 0.8),
		rgb($darkestPink, 0.8)
	);
	//161, 84, 161

	color: $light;

	animation: textHighlight linear;
	animation-fill-mode: forwards;
	animation-timeline: scroll();
	animation-iteration-count: 1;
	animation-range: contain 0% contain 25%;
	animation-duration: 1ms; /* Firefox requires this to apply the animation */

	font-weight: 800;
}

@keyframes textHighlight {
	0% {
		background-size: 0 100%;
	}
	50% {
		background-size: 100% 100%;
	}
	100% {
		background-size: 100% 100%;
	}
}

@media (max-width: $tablet) {
	.serviceContentContainer {
		gap: 8rem;
		margin: 5rem 2.5rem;

		.serviceCardsContainer {
			display: grid;
			grid-template-columns: 1fr;
			column-gap: 2rem;
		}
	}
}

@media (max-width: $small-tablet) {
	.servicesTop {
		.servicesTitle {
			font-size: 5rem;
			text-align: center;
			margin: auto;
		}

		.servicesSubtitle {
			margin: 3rem auto;
			width: 85%;

			.quoteText {
				width: 95%;
				margin: auto;
			}
		}
	}

	.servicesContainer {
		padding: 2rem;
		padding-top: 1rem;
	}
}

@media (max-width: $phone) {
	.servicesContainer {
		padding: 0;
		margin-top: 4rem;
	}

	.servicesTop {
		margin: 0rem auto;

		.servicesTitle {
			padding: 1.5rem;
			margin: auto;
			margin-left: -1rem;
		}

		.servicesSubtitle {
			margin: 1rem auto;

			.quoteLeft {
				max-height: 10rem;
			}

			.quoteRight {
				max-height: 9rem;
			}
		}
	}

	.serviceContentContainer {
		margin: 3rem auto;
		gap: 5rem;

		.serviceContentExtraInfo {
			padding: 2rem;
			margin-top: 2rem;
		}
	}

	video {
		display: none;
	}
}
