@import "../../../custom.scss";

.serviceCardContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	margin: 2rem;
	// background-color: rgba($light, 0.6);
	border: 1.5px solid $darkestPink;
	min-height: 400px;

	transition: transform 0.3s ease;

	&:hover {
		// background-color: rgba($light, 0.8);
		color: black;
		transform: scale(1.08);

		box-shadow: -1px -1px 10px $lightPink, 1px -1px 20px $lightPink,
			-1px 1px 30px $lightPink, 1px 1px 40px $lightPink;
	}

	width: 90%;

	.serviceCardContentHeader {
		min-height: 75px;
		text-align: left;
	}

	.serviceCardContentText {
		margin-top: 1rem;
		margin-bottom: auto;
		vertical-align: top;
		text-align: left;
	}
}

@media (max-width: $tablet) {
	.serviceCardContentContainer {
		width: 80%;
		margin: 2rem auto;

		min-height: 350px;

		.serviceCardContentHeader {
			min-height: auto;
			text-align: center;
		}

		.serviceCardContentText {
			text-align: center;
		}

		.serviceCardButtonContainer {
			align-items: center;
			margin: 1rem auto;
		}
	}
}

@media (max-width: $small-tablet) {
	.serviceCardContentContainer {
		width: 100%;
	}
}

@media (max-width: $phone) {
	.serviceCardsContainer {
		row-gap: 2rem;
	}

	.serviceCardContentContainer {
		width: 95%;
		padding: 0;
		margin: auto;
		min-height: auto;

		box-shadow: -1px -1px 10px $lightPink, 1px -1px 20px $lightPink,
			-1px 1px 30px $lightPink, 1px 1px 40px $lightPink;

		.serviceCardContentHeader {
			width: 100%;
		}

		.serviceCardContentText {
			text-align: center;
			padding: 0.5rem 0.7rem;
		}

		.serviceCardButtonContainer {
			align-items: center;
			margin: 1rem auto;
		}
	}
}
