@import "../../../custom.scss";

.navBar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin: 0 auto;
	// padding: 2rem 0rem;
	align-items: center;

	// width: 100vw;
	width: 97vw;
	height: 100%;

	.logoContainer {
		margin-left: 2rem;

		.logoSmall {
			width: 150px;

			-webkit-filter: invert(100%); /* Safari/Chrome */
			filter: invert(100%);
		}
	}
}

.menuContainer {
	a {
		margin: 1rem;
	}

	a.active {
		font-weight: 700;
		// text-decoration: underline !important;
		// text-underline-position: under;
		text-decoration: none !important;
	}

	.menuContainerFullNav {
		display: flex;
		margin-right: 1rem;
	}
	.menuContainerHamburger {
		display: none;
	}
}

@media (max-width: $tablet) {
	.menuContainer {
		.menuContainerFullNav {
			display: none;
		}
		.menuContainerHamburger {
			display: flex;
		}
	}
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
	.navBar {
		.logoContainer {
			margin-left: 0.5rem;

			.logoSmall {
				width: 95px;
			}
		}
	}
}
