@import "../../../custom.scss";

.homeGalleryContainer {
	margin: 3rem;
	margin-bottom: 7rem;
	margin-top: 0rem;
}

.slider-container {
	display: contents;

	max-height: 350px !important;
	margin: 2rem;
	margin-top: 3rem;

	&.slick-arrow {
		width: 50px !important;
	}

	.slick-slide {
		-webkit-filter: blur(0.3rem);
		filter: blur(0.3rem);

		img {
			margin: auto;
			width: auto;
			// max-width: 300px;
			max-height: 325px !important;
		}

		&.slick-center {
			-webkit-filter: blur(0);
			filter: blur(0);

			img {
				margin: auto;
				width: auto;
				max-width: auto;
				height: 350px !important;
				animation: imageGrow 0.2s;
			}
		}
	}
}

.slider-container-phone {
	display: none;

	// max-height: 350px !important;
	// max-width: fit-content !important;
	// margin: 2rem;
	// margin-top: 3rem;

	// &.slick-arrow {
	// 	width: 50px !important;
	// }

	// .slick-slide {
	// 	max-width: 650px !important;
	// 	img {
	// 		margin: auto;
	// 		width: auto;
	// 		// max-width: 300px;
	// 		max-height: 325px !important;
	// 	}

	// 	&.slick-center {
	// 		img {
	// 			margin: auto;
	// 			width: auto;
	// 			max-width: auto;
	// 			height: 350px !important;
	// 			animation: imageGrow 0.2s;
	// 		}
	// 	}
	// }
}

.sliderImageContainer {
	align-items: center;
	vertical-align: middle;
}

@keyframes imageGrow {
	from {
		transform: scale(0.5);
	}
	to {
		transform: scale(1);
	}
}

@media (max-width: $tablet) {
	.homeGalleryContainer {
		margin-left: 4rem;
		margin-right: 3rem;
	}

	.slider-container {
		.slick-slide {
			img {
				max-width: auto;
				max-height: 230px !important;
			}

			&.slick-center {
				img {
					max-width: auto;
					height: 260px !important;
				}
			}
		}
	}
}

@media (max-width: $small-tablet) {
	.homeGalleryContainer {
		// margin: auto;
		display: none;
	}

	.slider-container {
		display: none;
	}

	// .slider-container-phone {
	// 	display: contents;
	// }
}

@media (max-width: $phone) {
}
