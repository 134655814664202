@import "../../../custom.scss";

.faqCardContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;

	width: 95%;

	.faqCardContentHeader {
		// min-height: 75px;
		text-align: justify;
	}

	.faqCardContentText {
		margin-top: 0.5rem;
		margin-bottom: auto;
		vertical-align: top;
		text-align: justify;
	}

	.faqCardButtonContainer {
		margin-top: 0.5rem;
	}
}

@media (max-width: $tablet) {
	.faqCardContentContainer {
		width: 80%;
		margin: 0 auto;

		.faqCardContentHeader {
			min-height: auto;
			text-align: center;
		}

		.faqCardContentText {
			text-align: center;
		}

		.faqCardButtonContainer {
			align-items: center;
			margin: 1rem auto;
		}
	}
}

@media (max-width: $small-tablet) {
	.faqCardContentContainer {
		width: 95%;
		margin: 0 auto;
	}
}

@media (max-width: $phone) {
	.faqCardContentContainer {
		width: 100%;
		padding: 0.5rem 0;

		.faqCardButtonContainer {
			margin: 0rem auto;
		}
	}
}
