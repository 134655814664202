@import "../../../custom.scss";

.priceListCardContentContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 4rem;

	border: 1.5px solid white;

	min-height: 360px;

	.priceListCardContentLeft {
		width: 34%;
		padding: 2rem;

		img {
			max-width: 100%;
		}
	}

	.priceListCardContentMiddle {
		width: 30%;
		padding: 2rem;
		padding-left: 0rem;

		.priceListCardContentMiddleText {
			margin: 0.5rem 0rem;
		}
	}

	.priceListCardContentRight {
		width: 36%;
		padding: 2rem;
		margin: auto;
	}
}

.priceOneLineContainer {
	display: flex;
	flex-direction: row;

	margin: 0.5rem auto;

	.priceOneLineTitle {
		width: 70%;
		padding: 0rem 0.5rem;
	}

	.priceOneLinePrices {
		display: flex;
		flex-direction: row;
		gap: 0.1rem;
		vertical-align: bottom;

		width: 30%;
		padding: 0rem 0.5rem;

		.priceStrikeThrough {
			color: rgba(black, 0.5);
			text-decoration: line-through;
		}

		.priceEuroSymbol {
			margin-right: 0.5rem;
		}

		.priceArrow {
			color: $errorRed;
		}

		.priceCurrent {
			width: 100%;
			text-align: right;
		}
	}
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
	.priceListCardContentContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: auto;
		margin-bottom: 3rem;

		.priceListCardContentLeft {
			width: 90%;
			padding: 2rem;
			margin: auto;
		}

		.priceListCardContentMiddle {
			width: 90%;
			padding: 2rem;
			margin: auto;
		}

		.priceListCardContentRight {
			width: 90%;
			padding: 2rem;
			margin: auto;
		}

		.lineContainer {
			display: none;
		}
	}
}

@media (max-width: $phone) {
	.priceListCardContentContainer {
		.priceListCardContentLeft {
			width: 95%;
			padding: 1rem 0.5rem;
			margin: auto;
		}

		.priceListCardContentMiddle {
			padding: 0.5rem;
			margin: auto;
		}

		.priceListCardContentRight {
			padding: 0.5rem;
			margin: auto;
		}
	}
}
