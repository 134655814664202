@import "../../custom.scss";

.cookieBannerFull {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	min-width: 100vw;
	background-color: rgba(34, 37, 41);
	padding: 1rem 1.5rem;
	box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
	z-index: 1000;
	transition: opacity 0.3s ease, visibility 0.3s ease;
	opacity: 1;
	visibility: visible;

	min-height: 80px;

	display: grid;
	grid-template-columns: 1fr 50fr 1fr;

	&.fadeOut {
		opacity: 0;
		visibility: hidden;
	}

	&.minimized {
		width: auto;
		height: auto;
		padding: 0;
		background-color: transparent;
		box-shadow: none;
	}
}

.cookieContent {
	margin: auto;
	margin-left: 1.5rem;
	margin-right: 0.5rem;
	vertical-align: middle;

	div {
		display: grid;
		grid-template-columns: 5fr 1fr;
		text-align: left;
		align-content: left;

		.cookieText {
			display: block;
			padding-right: 10%;
		}

		.cookieTextFlex {
			margin: auto 0;
		}

		.cookieButtons {
			margin: auto 0;

			display: flex;
			flex-direction: row-reverse;

			button {
				margin: 0 0.5rem;
				margin-right: 1.2rem;
				padding: 0.5rem 0.8rem;

				color: #fff;
				font-weight: 600;

				border: none;
				cursor: pointer;

				&.cookieDecline {
					background-color: #555;
					&:hover {
						background-color: #777676;
					}
				}

				&.cookieAccept {
					background-color: #325bcd;
					&:hover {
						background-color: #4876f5;
					}
				}
			}
		}
	}
}

.closeIcon {
	font-size: 25px;
	cursor: pointer;
	margin: auto;
	margin-right: 5px;

	&:hover {
		cursor: pointer;
	}
}

.minimizedContainer {
	position: fixed;
	bottom: 10px;
	left: 10px;
	display: flex;
	align-items: center;
	z-index: 1001;

	.badge {
		background-color: #333;
		color: white;
		padding: 0.5rem;
		margin: 0.55rem 4.5rem;
		border-radius: 3px;
		font-size: 1rem;
	}
}

.fingerprintIcon {
	font-size: 24px;
	cursor: pointer;
	margin: auto 10px;

	&.minimized {
		position: fixed;
		bottom: 10px;
		left: 10px;
		width: 50px;
		height: 50px;
		background-color: #333;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
		z-index: 1001;
		cursor: pointer;
	}
}

.fadeInOut {
	animation: fadeInOut 3s forwards;
}

.fadeIn {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
}

.fadeOut {
	animation: fadeOut 1s ease-in-out;
	animation-fill-mode: forwards;
}

@keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
	.cookieBannerFull {
		padding: 1rem 0.5rem;
	}

	.cookieContent {
		margin: auto;

		div {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;

			.cookieText {
				font-size: 0.8rem;
				margin: auto;
				padding: 0.5rem;
			}

			.cookieButtons {
				margin: auto 0;

				display: flex;
				flex-direction: column-reverse;

				button {
					margin: 0.5rem;
				}
			}
		}
	}

	.fingerprintIcon {
		font-size: 18px;

		&.minimized {
			width: 35px;
			height: 35px;
		}
	}

	.minimizedContainer {
		.badge {
			padding: 0.2rem 0.5rem;
			margin: 0.5rem 3rem;
			font-size: 0.8rem;
		}
	}
}

@media (max-width: $phone) {
}
