@import "../../custom.scss";

.faqHeaderContainer {
	width: 100vw;
	height: 35vh;
	margin-left: calc(-50vw + 50% - 0px);

	display: flex;
	align-items: center;

	.faqHeader {
		width: 100%;
		text-align: right;
		padding-right: 10rem;
		vertical-align: middle;
	}
}

.faqContentContainer {
	margin-top: 3rem;
	padding: 5rem 2rem;

	.accordion {
		width: 80%;
		margin: auto;
	}

	.accordion-button,
	.accordion-button:not(.collapsed) {
		background-color: $pink;

		font-weight: 600;
		font-size: 1.5rem;
		color: white;

		box-shadow: none;

		&:focus {
			box-shadow: none;
		}

		&::after {
			color: white;
			-webkit-filter: invert(100%); /* Safari/Chrome */
			filter: invert(100%);
		}
	}

	.accordion-body {
		padding: 1.5rem 2rem;
		background-color: $lightPink;
		color: $darkerBrown;
	}
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
	.faqContainer {
		padding: 2rem;
	}

	.faqHeaderContainer {
		.faqHeader {
			font-size: 5rem;
			padding-right: 5rem;
		}
	}

	.faqContentContainer {
		margin-top: 3rem;
		padding: 0rem;

		.accordion {
			width: 95%;
			margin: auto;
		}
	}
}

@media (max-width: $phone) {
	.faqContentContainer {
		.accordion {
			width: 100%;
			margin: auto;
		}

		.accordion-button,
		.accordion-button:not(.collapsed) {
			font-size: 1rem;
		}
	}
}
