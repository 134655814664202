@import "../../custom.scss";

.agbContainer {
	margin: 5% auto;
	padding: 2rem;
	background-color: rgba($light, 0.5);

	display: flex;
	flex-direction: column;
	gap: 5rem;

	width: 70vw;
	height: 100%;
	max-width: 1300px;

	font-family: $text_montserrat;

	.agbHeader {
		margin: auto;
		padding: 3rem;
		text-align: center;
		font-family: $text_montserrat;
		font-size: 5rem;
	}
}

.agbTextContainer {
	margin: auto;
	padding: 5rem;
	padding-top: 2rem;
	margin-bottom: 3rem;

	.agbTextHeader {
		font-size: 1.5rem;
		font-weight: 500;
		margin-top: 2rem;
		margin-bottom: 0.5rem;
	}

	.agbText {
		font-size: 1rem;
		font-weight: 400;

		display: flex;
		flex-direction: row;

		padding-left: 2rem;
		margin-bottom: 0.5rem;

		div:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.agbBottomContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

@media (max-width: $tablet) {
	.agbContainer {
		width: fit-content;
		margin: auto;

		.agbHeader {
			font-size: 3rem;
		}

		.agbText {
			padding: 3rem;
			padding-top: 2rem;

			.agbTextHeader {
				font-size: 1.3rem;
			}

			.agbTextSubheader {
				font-size: 1rem;
			}
		}
	}
}

@media (max-width: $small-tablet) {
	.agbContainer {
		width: fit-content;
		padding: 2rem 0;

		.agbHeader {
			font-size: 2.3rem;
			padding: 1rem;
		}

		.agbText {
			padding: 1rem;
			padding-top: 0rem;
		}
	}
}

@media (max-width: $phone) {
	.agbContainer {
		width: 80vw;
		margin: auto;
		margin-bottom: 0;
		gap: 2rem;

		.agbHeader {
			font-size: 1.4rem;
			padding: 1rem;
		}

		.agbText {
			padding: 1rem;
			padding-top: 0.5rem;
			font-size: 0.8rem;

			.agbTextHeader {
				font-size: 1.1rem;
			}

			.agbTextSubheader {
				font-size: 0.8rem;
			}
		}
	}
}
