@import "../../custom.scss";

.homeContainer {
	display: flex;
	flex-direction: column;
	row-gap: 10rem;
}

@media (max-width: $tablet) {
	.homeContainer {
		margin-top: 7rem;
	}
}

@media (max-width: $small-tablet) {
	.homeContainer {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

@media (max-width: $phone) {
	.homeContainer {
		padding: 1.5rem;

		margin-top: 2rem;
		row-gap: 5rem;
	}
}
