@import "../../custom.scss";

.lineContainer {
	padding: 5px;
	margin: auto;
	text-align: center;
	vertical-align: middle;

	&.vertical,
	&.verticalLong {
		height: 100%;
	}

	&.horizontal {
		width: 100%;
	}

	.line {
		&.vertical {
			width: 1.5px;
			height: 15px;
		}

		&.verticalLong {
			width: 1.2px;
			min-height: 300px;
		}

		&.horizontal {
			height: 1.8px;
		}

		&.horizontalThin {
			height: 1.2px;
		}

		&.light {
			background-color: $light;
		}

		&.dark {
			background-color: $darkestPink;
		}

		&.grey {
			background-color: rgba($darkerBrown, 0.2);
		}
	}
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
	.lineContainer {
		padding-top: 10px;

		.line {
			&.vertical {
				width: 20px;
				height: 1.5px;
			}
		}
	}
}
