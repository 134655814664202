@import "../../custom.scss";

.impressumContainer {
	margin: 5% auto;
	padding: 2rem;
	background-color: rgba($light, 0.5);

	display: flex;
	flex-direction: column;
	gap: 5rem;

	width: 70vw;
	height: 100%;
	max-width: 1300px;

	.impressumHeader {
		margin: auto;
		padding: 3rem;
		padding-top: 5rem;
		text-align: center;
		font-family: $text_montserrat;
		font-size: 5rem;
	}
}

.impressumText {
	margin: auto;
	padding: 5rem;
	padding-top: 2rem;
	font-family: $text_montserrat;

	.impressumTextContainer {
		margin-bottom: 4rem;
	}

	.impressumTextHeader {
		font-size: 1.5rem;
		font-weight: 500;
	}

	.impressumTextSubheader {
		font-size: 1rem;
		font-weight: 500;
	}
}

@media (max-width: $tablet) {
	.impressumContainer {
		width: fit-content;
		margin: auto;

		.impressumHeader {
			font-size: 3rem;
		}

		.impressumText {
			padding: 3rem;
			padding-top: 2rem;

			.impressumTextHeader {
				font-size: 1.3rem;
			}

			.impressumTextSubheader {
				font-size: 1rem;
			}
		}
	}
}

@media (max-width: $small-tablet) {
	.impressumContainer {
		width: fit-content;
		padding: 2rem 0;

		.impressumHeader {
			font-size: 2.3rem;
			padding: 1rem;
		}

		.impressumText {
			padding: 1rem;
			padding-top: 0rem;
		}
	}
}

@media (max-width: $phone) {
	.impressumContainer {
		width: 80vw;
		margin: auto;
		margin-bottom: 0;
		gap: 2rem;

		.impressumHeader {
			font-size: 1.4rem;
			padding: 1rem;
		}

		.impressumText {
			padding: 1rem;
			padding-top: 0.5rem;
			font-size: 0.8rem;

			.impressumTextHeader {
				font-size: 1.1rem;
			}

			.impressumTextSubheader {
				font-size: 0.8rem;
			}
		}
	}
}
