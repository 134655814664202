@import "../../custom.scss";

.buttonCustom {
	// background-color: $light;
	padding: 5px;
	width: fit-content;

	&.light {
		border: 1px solid $light;
		color: $light;

		font-weight: 500;
	}
	&.light:hover {
		cursor: pointer;
		background-color: $light;
		color: $darkestPink;
		font-weight: 700;
	}

	&.dark {
		// background-color: $darkerBrown;
		// color: $light;
		border: 1px solid $darkerBrown;
		color: $darkerBrown;

		font-weight: 500;
	}
	&.dark:hover {
		// cursor: pointer;
		// background-color: $light;
		// color: $darkerBrown;
		// font-weight: 700;
		cursor: pointer;
		background-color: $light;
		color: $darkestPink;
		font-weight: 700;
	}

	&.transparent {
		border: 1px solid $light;
		background-color: transparent;
		color: $light;
	}
	&.transparent:hover {
		cursor: pointer;
		background-color: $light;
		color: $darkestBrown;
		font-weight: 700;
	}
}

@media (max-width: $tablet) {
	.switchOnSmall {
		padding: 0.5rem 2rem;
		font-weight: 900;

		&.light {
			// background-color: $darkestBrown;
			// color: $light;
		}

		&.dark {
			color: $darkestBrown;
			font-weight: 500;
		}

		&.transparent {
			border: none;
			background-color: $darkestBrown;
			color: $light;
		}
	}
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
	.buttonCustom {
		font-size: 1rem;
	}
	
	.switchOnSmall {
		padding: 0.2rem 1rem;
	}
}
