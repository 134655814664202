@import "../../custom.scss";

.priceListHeaderContainer {
	width: 100vw;
	height: 35vh;
	margin-left: calc(-50vw + 50% - 0px);
	margin-bottom: 3rem;

	display: flex;
	align-items: center;

	.priceListHeader {
		padding-left: 10rem;
		text-align: left;
		vertical-align: middle;
	}
}

.priceListContentContainer {
	padding: 3rem 2rem;

	.priceListContentHeader {
		margin-bottom: 2rem;
	}
}

.priceListExtraInfo {
	text-align: center;
	// font-weight: 600;
	margin: 8rem auto;
	width: 75%;
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
	.priceListContainer {
		padding: 2rem;
	}

	.priceListHeaderContainer {
		.priceListHeader {
			font-size: 5rem;
			padding-left: 3rem;
		}
	}

	.priceListContentContainer {
		margin-top: 3rem;
		padding: 2rem 0rem;
	}
}

@media (max-width: $phone) {
	.priceListContainer {
		padding: 2rem 0.5rem;
	}

	.priceListExtraInfo {
		margin: 7rem auto;
		width: 100%;
		padding: 0rem 1.5rem;
	}
}
