@import "../../custom.scss";

.aboutMeContainer {
	// border: 1px solid rgba($lighterPink, 0.5);
	// border-radius: 3rem;
	// background-color: rgba($lighterPink, 0.5);

	.aboutMeInnerContainer {
		// background-color: $light;
	}

	--b: 10px; /* thickness of the border */
	--c: #e7199d; /* color of the border */
	--w: 4.5rem; /* width of border */

	border: var(--b) solid #0000; /* space for the border */
	--_g: #0000 90deg, var(--c) 0;
	--_p: var(--w) var(--w) border-box no-repeat;
	background: conic-gradient(
				from 90deg at top var(--b) left var(--b),
				var(--_g)
			)
			0 0 / var(--_p),
		conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
			var(--_p),
		conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
			var(--_p),
		conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
			100% 100% / var(--_p);
}

.aboutMeHeaderContainer {
	// background-color: rgba($darkestPink, 0.7);
	width: 100vw;
	max-height: 450px;
	margin-left: calc(-50vw + 50% - 0px);
	position: relative;

	margin-top: 4.5rem;
	margin-bottom: 3.5rem;

	text-align: center;

	// with image
	// .aboutMeHeaderLogo {
	// 	position: absolute;
	// 	top: 20%;
	// 	left: 30%;
	// 	width: 20%;
	// }

	.aboutMeHeaderLogo {
		width: 35%;
		max-width: 600px;
		max-height: 450px;
		margin: 0rem 3rem;
		padding: 0rem 2rem;

		-webkit-filter: invert(100%); /* Safari/Chrome */
		filter: invert(100%);
	}

	img {
		margin: auto;
		width: 50%;
	}
}

.aboutMeContentContainer {
	margin: 7rem 5rem;
	margin-bottom: 10rem;
}

@media (max-width: $tablet) {
	.aboutMeContainer {
		width: 90%;
		margin: auto;
		margin-top: 5rem;
	}

	.aboutMeHeaderContainer {
		.aboutMeHeaderLogo {
			width: 100%;
			margin: 2rem;
		}
	}

	.aboutMeContentContainer {
		margin: 4rem 0rem;
		margin-bottom: 5rem;
	}
}

@media (max-width: $small-tablet) {
	.container {
		max-width: 700px !important;
	}

	.aboutMeContainer {
		width: 95%;
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

@media (max-width: $phone) {
	.aboutMeContainer {
		width: 95%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		.aboutMeHeaderContainer {
			padding: 1.5rem 0;
			margin-top: 2rem;
			margin-bottom: 1rem;

			.aboutMeHeaderLogo {
				margin: 0;
			}

			img {
				width: 100%;
			}
		}

		.aboutMeContentContainer {
			margin: 3rem 1rem;
		}
	}
}
