/* The following block can be included in a custom.scss */

/* Color Theme */
$light: #ffff;
$midBrown: #cdc7ba;
$darkBrown: #936e4c;
// $darkerBrown: #6d6560;
$darkerBrown: #807873;
$darkestBrown: #3f3b38;
$errorRed: #8c1f1f;
$lightPink: rgba(#fe87c3, 0.2);
$lighterPink: #ea95ca;
$pink: #f781be;
$darkerPink: #fe5cb0;
$darkestPink: #e7199d;
$darkestDarkPink: #940f63;
$barbieBlue: #8ebfe2;
// $barbieBeige: #f6eef3;
$barbieBeige: #f4d6e8;

/* Typography */
$text_montserrat: "Montserrat", sans-serif;
$text_cormorant: "Cormorant Garamond", serif;
$text_madi: "Ms Madi", cursive;
$text_bodoni: "Libre Bodoni", serif;
$text_parisienne: "Parisienne", cursive;

/* Screen Sizes */
$desktop: 1800px;
$tablet: 1030px; // 1024
$small-tablet: 720px; // 712
$phone: 490px; // 480
$small-phone: 370px;

// @media (max-width: $tablet) {}
// @media (max-width: $small-tablet) {}
// @media (max-width: $phone) {}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
