@import "../../../custom.scss";

.homeFaqsContainer {
	// width: 100vw;
	// margin-left: calc(-50vw + 50%);
	text-align: center;

	padding: 3rem 0;

	.homeFaqsSectionHeader {
		padding: 0 2rem;
		padding-top: 1rem;
	}

	.homeFaqsCardContainer {
		// display: flex;
		// flex-direction: row;

		width: 95%;
		margin: 3rem auto;

		justify-content: space-between;
		text-align: center;

		display: grid;
		grid-template-columns: 45% 45%;
		grid-column-gap: 2rem;
		grid-row-gap: 2.5rem;
	}
}

.verticalLine {
	background-image: linear-gradient(#000, #000);
	background-size: 2px 100%;
	background-repeat: no-repeat;
	background-position: center center;
}

@media (max-width: $tablet) {
	.homeFaqsContainer {
		// margin-left: calc(-50vw + 50%);

		margin-left: 4rem;
		margin-right: 3rem;

		.homeFaqsCardContainer {
			display: grid;

			// grid-template-columns: 1fr 1fr;
			// justify-content: space-between;
			// row-gap: 0.5rem;
			// padding: 2rem 0.5rem;
			grid-template-columns: 1fr;
			row-gap: 1rem;
			padding: 2rem 0rem;

			margin: auto;

			text-align: justify;
		}
	}
}

@media (max-width: $small-tablet) {
	.homeFaqsContainer {
		margin: auto;

		.homeFaqsSectionHeader {
			padding: 0;
			padding-top: 1rem;
		}
	}
}

@media (max-width: $phone) {
	.homeFaqsContainer {
		.homeFaqsCardContainer {
			width: 100%;
			gap: 4rem;
		}
	}
}
