@import "../../../custom.scss";

.homeCardContainer {
	display: flex;
	flex-direction: row;

	.homeCardImageContainer {
		width: 50%;
		margin: auto;
		text-align: center;

		img {
			margin: auto;

			&.mid {
				max-width: 80%;
				max-height: 550px;
			}

			&.big {
				max-width: 100%;
			}
		}
	}

	.homeCardContentContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 2rem;

		width: 50%;

		.homeCardContentText {
			padding: 1rem 0;
			text-align: justify;
		}
	}
}

.imageLeft {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.imageRight {
	display: flex;
	flex-direction: row-reverse;
	gap: 1rem;
}

@media (max-width: $tablet) {
	.homeCardContainer {
		display: flex;
		flex-direction: column;

		width: 80%;
		margin: auto;

		.homeCardImageContainer {
			width: 100%;
		}

		.homeCardContentContainer {
			display: flex;
			margin: auto;
			width: 100%;

			padding: 2rem 0;

			font-size: 1.5rem;
			font-weight: 500;

			.homeCardContentButton {
				float: right;
			}
		}
	}
}

@media (max-width: $small-tablet) {
	.homeCardContainer {
		width: 100%;

		.homeCardContentContainer {
			font-size: 1.2rem;
			font-weight: 500;
			padding: 0.5rem 0;

			.homeCardContentText {
				padding: 0;
			}
		}
	}
}

@media (max-width: $phone) {
	.homeCardContainer {
		width: 100%;

		.homeCardImageContainer {
			video {
				width: 80%;
			}
		}

		.homeCardContentContainer {
			font-size: 1.2rem;
			font-weight: 500;
			padding: 0.5rem 0;
		}
	}
}
