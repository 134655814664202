@import "../../../custom.scss";

.homeLogoContainer {
	position: relative;
	top: 0;
	left: 0;

	margin-bottom: 5rem;
	min-height: 700px;

	.blob {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0%);
		z-index: 1;

		opacity: 0.3;

		width: 650px;
		margin: auto;
	}

	.logoBigWhite {
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		width: 520px;
		margin: auto;

		-webkit-filter: invert(100%); /* Safari/Chrome */
		filter: invert(100%);
	}
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
	.homeLogoContainer {
		margin: 5rem auto;
		margin-top: 0rem;
		min-height: 300px;

		.blob {
			width: 98vw;
		}

		.logoBigWhite {
			width: 75vw;
			top: 60%;
		}
	}
}

@media (max-width: $small-phone) {
	.homeLogoContainer {
		.logoBigWhite {
			width: 75vw;
			top: 50%;
		}
	}
}
