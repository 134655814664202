@import "../../custom.scss";

.sectionHeaderContainer {
	&.normalFlex {
		display: flex;
		flex-direction: row;
		vertical-align: middle;
	}

	&.reverseFlex {
		display: flex;
		flex-direction: row-reverse;
		vertical-align: middle;
	}

	.sectionHeader {
		max-width: 150px;
		min-width: fit-content;
		padding-right: 1rem;

		&.light {
			color: $light;
		}

		&.dark {
			color: $darkerBrown;
		}

		&.middle {
			padding: 1rem;
			margin: auto;
			margin-top: 2rem;
		}

		&.short {
			max-width: 300px;
			min-width: 250px;
			margin-top: 0;
			text-align: center;
		}
	}

	.extraBig {
		font-size: 5rem;
	}
}

@media (max-width: $tablet) {
	.sectionHeaderContainer {
		padding-bottom: 2rem;
	}
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
	.sectionHeaderContainer {
		padding: 0.5rem;
	}
}
