@import "~bootstrap/scss/bootstrap";
@import "../custom.scss";

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ms+Madi&family=Parisienne&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;

	scrollbar-gutter: stable;
	// width: calc(100vw - 6px);

	width: 100vw;
	max-width: 100vw;
	box-sizing: border-box;

	// height: calc(80vh - 20px);
	z-index: 1;
	// scrollbar-gutter: stable both-edges;
	white-space: initial;

	overflow-x: hidden;
}

.pageContainer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	row-gap: 3rem;
	position: relative;
}

.container {
	flex: 1;
}

/* -------- Bootstrap Modal -------- */
.modal-open {
	padding-right: 0px !important;
	// position: fixed;
}

/* -------- Scroll Bar -------- */
* {
	scrollbar-width: thin;
	scrollbar-color: #888 none;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* -------- Shared Theme -------- */
.backgroundLight {
	background-color: $barbieBeige;
}

.backgroundDark {
	// background-color: rgba($barbieBeige, 0.5);
	// new background image
	background-image: url("../assets/background.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	width: 100%;
	height: 150%;

	position: relative;
	isolation: isolate;
}

.backgroundDark::after {
	content: "";
	position: absolute;
	background: white;
	z-index: -1;
	inset: 0;
	opacity: 0.45;
}

.fontLight {
	color: $light;

	a {
		color: $light;
	}
}

.fontDark {
	color: $darkestPink;

	a {
		color: $darkestPink;
	}
}

.fontBlack {
	color: $darkerBrown;
}

.fontMenu {
	font-family: $text_montserrat;
	font-weight: 300;
	font-size: 1rem;
}

.fontFooter {
	font-family: $text_montserrat;
	font-weight: 600;
}

.fontHeaderBig {
	font-family: $text_parisienne;
	font-style: italic;
	font-size: 8rem;
	font-weight: 700;
}

.fontHeader {
	font-family: $text_parisienne;
	font-weight: 700;
	font-size: 2.5rem;
}

.fontSubheader {
	font-family: $text_montserrat;
	font-weight: 600;
	font-size: 1.2rem;
}

.fontText {
	font-family: $text_montserrat;
	font-weight: 400;
	font-size: 1.05rem;
}

.fontTextBold {
	font-family: $text_montserrat;
	font-weight: 700;
	font-size: 1.3rem;
}

.fontCursive {
	font-family: $text_parisienne;
}

.fontNumber {
	font-family: $text_montserrat;
	font-weight: 700;
}

.fontFormal {
	font-family: $text_montserrat;
	font-weight: 500;
}

.fontExtraInfo {
	font-family: $text_montserrat;
	font-weight: 400;
	font-size: 1.3rem;
}

.fontNeon {
	color: white !important;
	text-shadow: -1px -1px 7px $darkestPink, 1px -1px 10px $darkestPink,
		-1px 1px 21px $darkestPink, 1px 1px 42px $darkestPink;

	// -webkit-text-fill-color: $light;
	// -webkit-text-stroke: 0.5px $darkestPink;
}

/* -------- Responsive -------- */
@media (max-width: $tablet) {
	body {
		width: 100vw;
		height: 100vh;
		box-sizing: border-box;
	}

	.pageContainer {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.pageContainer::-webkit-scrollbar {
		display: none;
	}
}

@media (max-width: $small-tablet) {
	.pageContainer {
		row-gap: 0rem;
	}
}

@media (max-width: $phone) {
	.fontMenu {
		font-size: 1rem;
	}

	.fontHeaderBig {
		font-size: 3rem;
	}

	.fontHeader {
		font-size: 1.7rem;
	}

	.fontSubheader {
		font-size: 1rem;
	}

	.fontText {
		font-size: 0.9rem;
	}

	.fontTextBold {
		font-size: 1.1rem;
	}

	.fontExtraInfo {
		font-size: 1.15rem;
	}
}

@media (max-width: $phone) {
	.fontBlack {
		color: $darkestBrown;
	}
}
